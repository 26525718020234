@use "sass:math";
@import "~evostrap/evostrap-core/src/css/functions";
@import "~evostrap/evostrap-core/src/css/variables";
//──── ERPL SOURCES ──────────────────────────────────────────────────────────────────────
// Mobile first
@media (min-width: map-get($map: $grid-breakpoints, $key: lg)) {
    .erpl_tt-sources .erpl_tt-sources-list {
        list-style: none;
        padding: 0;
        background: url('../../img/sources/sources/head_centeredw.jpg') center center no-repeat;
        min-height: 620px;
        height: 620px //ie
    }
    .erpl_tt-sources .erpl_tt-sources-list .erpl_tt-sources-item-description {
        display: none;
    }
    #erpl_tt-sources-item-impactassessment,
    #erpl_tt-sources-item-membersresearchservice,
    #erpl_tt-sources-item-policydepartments {
        width: calc(100% / 3);
    }
    #erpl_tt-sources-item-europeanaddedvalue,
    #erpl_tt-sources-item-stoa,
    #erpl_tt-sources-item-eurobarometer,
    #erpl_tt-sources-item-economicgovernance {
        width: 25%;
    }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
    .erpl_tt-sources-item-title.erpl_title-h3 {
        white-space: normal;
        text-align: left;
    }
}

.erpl_tt-sources-item button:hover {
    cursor: pointer;
}

.erpl_tt-sources-item button:hover .erpl_title-h3 {
    color: $primary
}

.erpl_tt-sources .erpl_tt-sources-list .erpl_tt-sources-item .erpl_tt-sources-item-picture {
    background-image: url('../../img/sources/sources/tt-sources-sprites.png');
    min-width: 106px;
}

.erpl_tt-sources-item-picture-head-centered {
    background-position: -10px -0px;
    width: 195px;
    height: 331px;
}

.erpl_tt-sources-item-picture-economicgovernance {
    background-position: -10px -341px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-economicgovernance {
    background-position: -10px -457px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-eurobarometer {
    background-position: -10px -573px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-eurobarometer {
    background-position: -10px -689px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-europeanaddedvalue {
    background-position: -10px -805px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-europeanaddedvalue {
    background-position: -10px -921px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-impactassessment {
    background-position: -10px -1037px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-impactassessment {
    background-position: -10px -1153px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-membersresearchservice {
    background-position: -10px -1269px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-membersresearchservice {
    background-position: -10px -1385px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-policydepartments {
    background-position: -10px -1501px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-policydepartments {
    background-position: -10px -1617px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item-picture-stoa {
    background-position: -10px -1733px;
    width: 106px;
    height: 106px;
}

.erpl_tt-sources-item button:hover .erpl_tt-sources-item-picture-stoa {
    background-position: -10px -1849px;
    width: 106px;
    height: 106px;
}

//──── box social media (home) ────────────────────────────────────────────────────────────────
.min-width-100 {
    min-width: 100px;
}

//──── bloc (home) ────────────────────────────────────────────────────────────────
@media (max-width: map-get($map: $grid-breakpoints,
$key: md)) {
    .erpl_home-tt {
        margin-top: 0px;
        margin-left: -3px;
    }
}

@media (min-width: map-get($map: $grid-breakpoints,
$key: lg)) {
    .erpl_home-tt {
        margin-top: -145px;
        margin-left: -18px;
    }
}

.erpl_bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        border: 1px solid  $gray-300; 
        border-radius: 50%;
        margin: auto;
        .bubble {
            position: absolute;
            transition: 1s;
            pointer-events: none;

            &:hover, &:focus {
                z-index: 9;
            }
            .bubble-content {
                cursor: pointer;
                pointer-events: all;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                position: relative;
                padding: 0;
                flex-direction: column;
                border:1px solid $gray-500;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto
                }

                p {
                    background: rgba($primary,.85);
                    display: block;
                    width: 100%;
                    white-space: normal;
                    position: absolute;
                    color: white;
                    margin: 0;

                }

                &.circle {
                    border-radius: 50%;
                }

                &:focus, &:hover {
                    border: 2px solid $primary;

                    p {
                        background: $primary;
                       
                    }
                }
            }

            
        }
    }
}